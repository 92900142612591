<template>
  <div>
    <div class="page-header panel">
      <h3 class="title">Нийт жолоочийн багийн жагсаалт</h3>
      <div class="action-section">
        <el-button
          type="success"
          size="mini"
          icon="el-icon-plus"
          @click="dialogFormVisible = true"
          >Нэмэх</el-button
        >
      </div>
    </div>
    <el-row>
      <el-col :span="24" :offset="0">
        <div class="panel">
          <el-table
            size="mini"
            :data="allDriverTeam"
            style="width: 100%"
            v-loading="isDataLoading"
          >
            <el-table-column label="ID" prop="id"> </el-table-column>
            <el-table-column label="Нэр" prop="group_name"> </el-table-column>
            <el-table-column align="right">
              <template slot-scope="scope">
                <el-button
                  type="info"
                  size="mini"
                  @click="handleEdit(scope.row)"
                  >Засах</el-button
                >
                <el-button
                  type="danger"
                  size="mini"
                  @click="deleteTeam(scope.row)"
                  >Устгах</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <el-dialog title="Багийн бүртгэл үүсгэх" :visible.sync="dialogFormVisible">
      <el-form :model="form">
        <el-form-item label="Багийн нэр" :label-width="formLabelWidth">
          <el-input
            v-model="form.group_name"
            placeholder="Нэр оруулна уу"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="dialogFormVisible = false"
          >Буцах</el-button
        >
        <el-button size="mini" type="success" @click="createTeam()"
          >Нэмэх</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="Багийн бүртгэл засварлах"
      :visible.sync="dialogEditFormVisible"
    >
      <el-form :model="rowData">
        <el-form-item label="Багийн нэр" :label-width="formLabelWidth">
          <el-input
            v-model="rowData.group_name"
            placeholder="Нэр оруулна уу"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="dialogEditFormVisible = false"
          >Буцах</el-button
        >
        <el-button size="mini" type="success" @click="updateTeam()"
          >Засах</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import service from "@/helpers/services.js";
export default {
  mounted() {
    this.getDriverList();
  },
  data() {
    return {
      isDataLoading: false,
      form: {
        group_name: ""
      },
      allDriverTeam: [],
      rowData: {},
      dialogFormVisible: false,
      dialogEditFormVisible: false,
      formLabelWidth: "120px"
    };
  },
  methods: {
    getDriverList() {
      this.isDataLoading = true;
      service.getDriverTeamList().then(response => {
        if (response.status === "success") {
          this.allDriverTeam = response.data;
          this.isDataLoading = false;
        } else {
          this.isDataLoading = false;
          this.$notify({
            title: "Алдаа",
            message: response.message,
            type: "warning"
          });
        }
      });
    },
    createTeam() {
      service.createDriverTeam(this.form).then(response => {
        if (response.status == "success") {
          this.$notify({
            title: "Success",
            message: "Амжилттай нэмэгдлээ",
            type: "success"
          });
          this.dialogFormVisible = false;
          this.getDriverList();
        } else {
          this.$notify.error({
            title: "Error",
            message: "Алдаа гарлаа"
          });
        }
      });
    },
    deleteTeam(data) {
      this.$confirm("Жолоочийн групп устгахдаа итгэлтэй байна уу?", "Анхаар", {
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        type: "warning"
      })
        .then(() => {
          service.deleteDriverTeam(data).then(response => {
            if (response.status == "success") {
              this.$notify({
                title: "Success",
                message: "Амжилттай устгалаа",
                type: "success"
              });
              this.dialogFormVisible = false;
              this.getDriverList();
            } else {
              this.$notify.error({
                title: "Error",
                message: "Алдаа гарлаа"
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Буцлаа"
          });
          this.openFullScreenLoader(false);
        });
    },
    updateTeam() {
      service.updateDriverGroup(this.rowData).then(response => {
        if (response.status == "success") {
          this.$notify({
            title: "Success",
            message: "Амжилттай засварлагдлаа",
            type: "success"
          });
          this.dialogEditFormVisible = false;
          this.getDriverList();
        } else {
          this.$notify.error({
            title: "Error",
            message: "Алдаа гарлаа"
          });
        }
      });
    },
    handleEdit(data) {
      this.rowData = data;
      this.dialogEditFormVisible = true;
    }
  }
};
</script>
